<template>
  <v-container>
    <v-card
        elevation="2"
        class="mt-8 px-4 py-4"
    >
        <form>
            <v-text-field
                v-model="email"
                :error-messages="emailErrors"
                label="Email"
                required
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
            ></v-text-field>

            <v-text-field
                v-model="password"
                :error-messages="passwordErrors"
                label="Password"
                type="password"
                required
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
            ></v-text-field>


            <v-btn
                class="mr-4 mt-4"
                @click="submit()"
            >
            Login
            </v-btn>
        </form>
    </v-card>
    
  </v-container>

</template>

<script>
  
  import { validationMixin } from 'vuelidate'
  import { required, minLength, email } from 'vuelidate/lib/validators'

  export default {
    name: 'Login',
    components: {

    },
    mixins: [validationMixin],

    validations: {
      password: { required, minLength: minLength(8)  },
      email: { required, email }
    },

    data: () => ({
      email: 'test@gmail.com',
      password: '123456789',
    }),

    computed: {
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Password must be at most 8 characters long')
        !this.$v.password.required && errors.push('Password is required.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
    },

    methods: {
      submit () {
        this.$v.$touch()
        console.log('-----submit');
        this.$store.dispatch('login', {email: this.email, password: this.password})
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.email = ''
        this.select = null
        this.checkbox = false
      },
    },
  }
</script>
